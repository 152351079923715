<template>
  <div class="text-center">
    <v-card style="position:relative;">
      <v-form
        ref="entryForm"
        @submit.prevent="submit"
        style="position: relative;"
      >
        <div>
          <v-toolbar
            elevation="1"
            style="position: sticky; top:64px; z-index:1;"
          >
            <!-- <v-toolbar flat style="background:hsla(60,70%,60%,0.5)"> -->
            <v-spacer></v-spacer>
            <v-toolbar-title class="indigo--text font-weight-bolder">
              FORMULIR PENAMBAHAN ASET
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <template v-slot:extension>
              <v-tabs
                :align-with-title="true"
                v-model="currentItem"
                fixed-tabs
                slider-color="indigo"
              >
                <v-tab
                  class="indigo--text"
                  v-for="(item, index) in items"
                  :key="index"
                  disabled
                >
                  {{ item }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>

          <v-tabs-items v-model="currentItem">
            <v-tab-item
              v-for="(item, index) in items"
              :key="index"
              :value="index"
            >
              <v-card flat>
                <div style="min-height:70vh;">
                  <v-row
                    v-if="index === 0"
                    no-gutters
                    style="padding: 5px 15px;width:70%;margin:auto;"
                  >
                    <v-col
                      md="12"
                      style="padding: 0;display:flex;justify-content:center;align-items:center;margin-bottom:0;"
                    >
                      <div style="padding: 0 10px;width:100%;">
                        <v-radio-group>
                          <v-col
                            cols="12"
                            style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                          >
                            <v-checkbox
                              v-model="form.searcFromEcatalogue"
                              style=" margin:0; height:25px; padding:0;"
                            ></v-checkbox>
                            <p
                              style="
                      margin:0;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Cari dari ecatalogue
                            </p>
                          </v-col>
                        </v-radio-group>
                      </div>
                    </v-col>
                    <v-col
                      v-if="form.searcFromEcatalogue"
                      md="12"
                      style="padding: 0;margin-bottom:20px;"
                    >
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0;margin-bottom:10px;">
                          <p
                            class="text-left"
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Cari Disini
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-autocomplete
                            v-model="selectedAsset"
                            dense
                            outlined
                            item-text="name"
                            item-id="id"
                            cache-items
                            hide-details
                            hide-no-data
                            return-object
                            label="- Ketik nama barang -"
                            :loading="loading"
                            :items="result"
                            :search-input.sync="search"
                            clearable
                            :rules="selectedNameRules"
                          ></v-autocomplete>
                        </v-col>
                      </div>
                    </v-col>

                    <v-col cols="12" v-if="form.searcFromEcatalogue">
                      <v-row no-gutters v-if="selectedAsset !== null">
                        <v-col md="12" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Kode Aset
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0;">
                              <v-text-field
                                :value="selectedAsset.code"
                                dense
                                outlined
                                readonly
                                :rules="selectedCodeRules"
                              />
                            </v-col>
                          </div>
                        </v-col>

                        <v-col md="12" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Unit
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0;">
                              <v-text-field
                                :value="
                                  selectedAsset.unit !== null
                                    ? selectedAsset.unit.name
                                    : ''
                                "
                                dense
                                outlined
                                readonly
                                :rules="unitRules"
                              />
                            </v-col>
                          </div>
                        </v-col>

                        <v-col md="12" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Kategori Aset
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-text-field
                                :value="
                                  selectedAsset.category !== null
                                    ? selectedAsset.category.name
                                    : ''
                                "
                                dense
                                outlined
                                readonly
                                :rules="categoryRules"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <v-col md="12" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Merk
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-text-field
                                :value="selectedAsset.merk"
                                dense
                                outlined
                                readonly
                              />
                            </v-col>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" v-else>
                      <v-row no-gutters>
                        <v-col md="9" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Kategori
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-autocomplete
                                v-model="form.category"
                                :items="getDropdownAssetCategory"
                                item-text="name"
                                item-value="id"
                                return-object
                                dense
                                outlined
                                label="- Pilih kategori -"
                                :rules="categoryRules"
                              ></v-autocomplete>
                            </v-col>
                          </div>
                        </v-col>
                        <v-col
                          md="3"
                          style="padding: 0;"
                          class="d-flex justify-center align-center"
                        >
                          <v-dialog v-model="dialog2" width="500">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                :disabled="form.searcFromEcatalogue"
                                v-bind="attrs"
                                v-on="on"
                                color="green"
                                rounded
                                outlined
                                elevation="2"
                                class="white--text"
                                style="margin-bottom:10px; width:80%; font-size:12px;font-weight:bold;"
                              >
                                Tambah kategori
                              </v-btn>
                            </template>

                            <v-card>
                              <v-card-title class="text-h5 grey lighten-2">
                                Tambah Kategori
                              </v-card-title>

                              <v-card-text
                                class="d-flex justify-center align-center"
                              >
                                <v-col
                                  md="12"
                                  style="padding: 0; position:relative; top:20px;"
                                >
                                  <div style="padding: 0 10px">
                                    <v-col cols="12" style="padding: 0">
                                      <p
                                        class="text-left"
                                        style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                      >
                                        Nama Kategori
                                      </p>
                                    </v-col>
                                    <v-col cols="12" style="padding: 0;">
                                      <v-text-field
                                        v-model="category.name"
                                        outlined
                                        :rules="categoryNameRules"
                                        :disabled="loading"
                                      />
                                    </v-col>
                                    <v-col cols="12" style="padding: 0">
                                      <p
                                        class="text-left"
                                        style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                      >
                                        Kode
                                      </p>
                                    </v-col>
                                    <v-col cols="12" style="padding: 0;">
                                      <v-text-field
                                        v-model="category.code"
                                        outlined
                                        :disabled="loading"
                                      />
                                    </v-col>
                                  </div>
                                </v-col>
                              </v-card-text>

                              <v-divider></v-divider>

                              <v-card-actions>
                                <v-btn
                                  color="error"
                                  text
                                  outlined
                                  @click.prevent="categoryClose"
                                  :loading="loading"
                                >
                                  Batal
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="primary"
                                  text
                                  outlined
                                  @click.prevent="categoryAdd"
                                  :loading="loading"
                                >
                                  Simpan
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-col>
                        <v-col md="12" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Nama Aset
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0;">
                              <v-text-field
                                v-model="form.name"
                                dense
                                outlined
                                :rules="nameRules"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <v-col md="12" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Kode Aset
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0;">
                              <v-text-field
                                v-model="form.code"
                                dense
                                outlined
                                :rules="codeRules"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <v-col md="12" style="padding: 0;" class="d-flex">
                          <v-col cols="12" md="6" style="padding: 0">
                            <div style="padding: 0 10px">
                              <v-col cols="12" style="padding: 0">
                                <p
                                  class="text-left"
                                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                >
                                  Merk
                                </p>
                              </v-col>
                              <v-col cols="12" style="padding: 0">
                                <v-text-field
                                  v-model="form.merk"
                                  dense
                                  outlined
                                />
                              </v-col>
                            </div>
                          </v-col>

                          <v-col cols="12" md="6" style="padding: 0">
                            <div style="padding: 0 10px">
                              <v-col cols="12" style="padding: 0">
                                <p
                                  class="text-left"
                                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                >
                                  Unit
                                </p>
                              </v-col>
                              <v-col cols="12" style="padding: 0;">
                                <v-autocomplete
                                  v-model="form.unit"
                                  :items="getDropdownUnit"
                                  dense
                                  outlined
                                  item-text="name"
                                  item-value="id"
                                  return-object
                                  label="- Pilih unit -"
                                  :rules="unitRules"
                                ></v-autocomplete>
                              </v-col>
                            </div>
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col md="12" style="padding: 0;">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0;margin-bottom:10px;">
                          <p
                            class="text-left"
                            style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                          >
                            Kepemilikan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0;">
                          <v-select
                            label="- Pilih plant -"
                            dense
                            v-model="form.company"
                            :items="dropDownCompany"
                            item-text="name"
                            item-value="id"
                            return-object
                            outlined
                            clearable
                            :disabled="
                              getUserProfile.level.find(
                                ({ id }) => id === '1' || id === '29'
                              ) === undefined
                            "
                          ></v-select>
                        </v-col>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="index === 1"
                    no-gutters
                    style="padding: 5px 15px;width:70%;margin:auto;"
                  >
                    <v-col
                      md="12"
                      style="padding: 0;display:flex;justify-content:center;align-items:center;margin-bottom:0;"
                    >
                      <v-radio-group
                        v-model="form.submitType"
                        style="width:100%;"
                      >
                        <div
                          class="d-flex justify-content-between align-content-between"
                        >
                          <div
                            style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                          >
                            <!-- <v-checkbox
                              v-model="form.matchWithAssetCode"
                              style=" margin:0; height:25px; padding:0;"
                            ></v-checkbox> -->
                            <v-radio
                              value="matchwithassetcode"
                              style=" margin:0; height:25px; padding:0;"
                            ></v-radio>
                            <p
                              style="
                      margin:0;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Samakan lot dengan kode asset
                            </p>
                          </div>
                          <div
                            style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                          >
                            <v-radio
                              value="manual"
                              style=" margin:0; height:25px; padding:0;"
                            ></v-radio>
                            <p
                              style="
                      margin:0;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Tambah Manual
                            </p>
                          </div>
                          <div
                            style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                          >
                            <v-radio
                              value="random"
                              style=" margin:0; height:25px; padding:0;"
                            ></v-radio>
                            <p
                              style="
                      margin:0;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Random Generate
                            </p>
                          </div>
                          <div
                            style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                          >
                            <v-radio
                              value="sequence"
                              style=" margin:0; height:25px; padding:0;"
                            ></v-radio>
                            <p
                              style="
                      margin:0;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                            >
                              Sequence Generate
                            </p>
                          </div>
                        </div>
                      </v-radio-group>
                    </v-col>
                    <v-col md="12" style="padding:0;">
                      <v-row no-gutters>
                        <!-- ---------------------------------------------------RANDOM------------------------------------------------------------ -->
                        <v-col
                          v-if="form.submitType === 'random'"
                          md="12"
                          style="padding: 0;display:flex;justify-content:center;align-items:center;margin-bottom:10px;"
                        >
                          <div style="padding: 0 10px;width:100%;">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Tipe lot
                              </p>
                            </v-col>
                            <v-col
                              cols="12"
                              style="padding: 0; justify-content:flex-start; align-items:center; display:flex;margin-bottom:10px;"
                            >
                              <v-checkbox
                                v-model="form.random_type"
                                value="1"
                                style=" margin:0; height:25px; padding:0;"
                              ></v-checkbox>
                              <p
                                style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);margin-right:20px;
                      "
                              >
                                Numeric
                              </p>

                              <v-checkbox
                                v-model="form.random_type"
                                value="2"
                                style=" margin:0; height:25px; padding:0;"
                              ></v-checkbox>
                              <p
                                style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Aplha numeric
                              </p>
                            </v-col>
                          </div>
                        </v-col>
                        <v-col
                          v-if="form.submitType === 'random'"
                          cols="12"
                          style="padding: 0"
                        >
                          <div style="padding: 0 10px">
                            <v-col cols="6" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Jumlah digit
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0;">
                              <v-text-field
                                v-model="form.len_digit_number"
                                dense
                                outlined
                                type="number"
                                :rules="digitRules"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <v-col
                          v-if="form.submitType === 'random'"
                          cols="12"
                          style="padding: 0"
                        >
                          <div style="padding: 0 10px">
                            <v-col cols="6" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Jumlah lot
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0;">
                              <v-text-field
                                v-model="form.number_of_lot_no"
                                dense
                                outlined
                                type="number"
                                :rules="lotTotalRules"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <!-- ---------------------------------------------------SEQUENCE----------------------------------------------------------- -->
                        <v-col
                          v-if="form.submitType === 'sequence'"
                          cols="12"
                          style="padding: 0"
                        >
                          <div style="padding: 0 10px">
                            <v-col cols="6" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Jumlah Lot
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0;">
                              <v-text-field
                                v-model="form.number_of_lot_no"
                                dense
                                outlined
                                type="number"
                                :rules="lotTotalRules"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <v-col
                          v-if="form.submitType === 'sequence'"
                          cols="12"
                          style="padding: 0;"
                        >
                          <div style="padding: 0 10px">
                            <v-col cols="6" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Start Index
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0;">
                              <v-text-field
                                v-model="form.start_index"
                                dense
                                outlined
                                type="number"
                                style=" height:42px;"
                                :rules="indexRules"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <v-col
                          v-if="form.submitType === 'sequence'"
                          md="12"
                          style="padding: 0;display:flex;justify-content:center;align-items:center;margin-bottom:0;"
                        >
                          <div style="padding: 0 10px;width:100%;">
                            <v-radio-group v-model="form.padding_place">
                              <v-col cols="12" style="padding: 0">
                                <p
                                  class="text-left"
                                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                                >
                                  Padding
                                </p>
                              </v-col>
                              <v-col
                                cols="12"
                                style="padding: 0; justify-content:flex-start; align-items:center; display:flex;margin-bottom:10px;"
                              >
                                <v-radio
                                  value="left"
                                  style=" margin:0; height:25px; padding:0;"
                                ></v-radio>
                                <p
                                  style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);width:40px;
                      "
                                >
                                  Left
                                </p>

                                <div
                                  v-if="form.padding_place === 'left'"
                                  style="margin-left:10px;height:42px; width:120px;"
                                >
                                  <v-text-field
                                    v-model="form.number_of_padding"
                                    :hidden="form.padding_place === 'right'"
                                    dense
                                    outlined
                                    type="number"
                                    label="Padding Number"
                                    :rules="paddingNumber"
                                  />
                                </div>
                                <div
                                  v-if="form.padding_place === 'left'"
                                  style="margin-left:10px;height:42px; width:120px;"
                                >
                                  <v-text-field
                                    v-model="form.padding_with"
                                    :hidden="form.padding_place === 'right'"
                                    dense
                                    outlined
                                    label="character"
                                    :rules="paddingWith"
                                  />
                                </div>
                              </v-col>
                              <v-col
                                cols="12"
                                style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                              >
                                <v-radio
                                  value="right"
                                  style=" margin:0; height:25px; padding:0;"
                                ></v-radio>
                                <p
                                  style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);width:40px;
                      "
                                >
                                  Right
                                </p>
                                <div
                                  v-if="form.padding_place === 'right'"
                                  style="margin-left:10px;height:42px; width:120px;"
                                >
                                  <v-text-field
                                    v-model="form.number_of_padding"
                                    :hidden="form.padding_place === 'left'"
                                    dense
                                    outlined
                                    type="number"
                                    label="Padding Number"
                                    :rules="paddingNumber"
                                  />
                                </div>
                                <div
                                  v-if="form.padding_place === 'right'"
                                  style="margin-left:10px;height:42px; width:120px;"
                                >
                                  <v-text-field
                                    v-model="form.padding_with"
                                    :hidden="form.padding_place === 'left'"
                                    dense
                                    outlined
                                    label="character"
                                    :rules="paddingWith"
                                  />
                                </div>
                              </v-col>
                            </v-radio-group>
                          </div>
                        </v-col>
                        <!-- ---------------------------------------------------MANUAL-------------------------------------------------------------- -->
                        <v-col
                          v-if="form.submitType === 'manual'"
                          md="12"
                          style="padding: 0"
                        >
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Lot
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0;">
                              <v-text-field
                                v-model="form.lot_no"
                                dense
                                outlined
                                :rules="lotRules"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <!-- --------------------------------------SAME WITH ASSET CODE------------------------------------------------------------- -->

                        <v-col
                          v-if="form.submitType === 'matchwithassetcode'"
                          md="12"
                          style="padding: 0"
                        >
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Lot
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0;">
                              <v-text-field
                                v-if="selectedAsset === null"
                                :value="form.code"
                                dense
                                outlined
                                readonly
                                :rules="lotRules"
                              />
                              <v-text-field
                                v-else
                                :value="selectedAsset.code"
                                dense
                                outlined
                                readonly
                                :rules="lotRules"
                              />
                            </v-col>
                          </div>
                        </v-col>
                        <!-- -------------------------------------------------FIXED COLUMN---------------------------------------------------------------------- -->
                        <v-col md="12" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Kepemilikan
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0;">
                              <v-select
                                label="- Pilih plant -"
                                dense
                                v-model="form.company"
                                :items="dropDownCompany"
                                item-text="name"
                                item-value="id"
                                return-object
                                outlined
                                readonly
                                :rules="companyRules"
                              ></v-select>
                            </v-col>
                          </div>
                        </v-col>
                        <v-col md="12" style="padding: 0;">
                          <div style="padding: 0 10px">
                            <v-col
                              cols="12"
                              style="padding: 0;margin-bottom:10px;"
                            >
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Lokasi/Gudang
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0;">
                              <v-select
                                v-model="form.location"
                                :items="getDropdownLocation"
                                label="- Pilih -"
                                dense
                                item-text="name"
                                item-value="id"
                                return-object
                                outlined
                                clearable
                                :rules="locationRules"
                              ></v-select>
                            </v-col>
                          </div>
                        </v-col>
                        <v-col md="12" style="padding: 0">
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                              >
                                Tanggal Beli
                              </p>
                            </v-col>
                            <v-col cols="6" style="padding: 0;">
                              <v-text-field
                                v-model="form.purchase_date"
                                dense
                                outlined
                                type="date"
                                name="date"
                                step="1"
                                clearable
                                :rules="purchaseRules"
                              />
                            </v-col>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
                <v-card-actions
                  class="d-flex"
                  style="width:70%;margin:auto; padding: 0 15px;"
                >
                  <v-btn
                    v-if="currentItem === 0"
                    elevation="0"
                    color="error"
                    class="ma-2"
                    @click.prevent="close"
                    large
                    style="width:40%;"
                    type="reset"
                  >
                    Batal
                  </v-btn>
                  <v-btn
                    @click.prevent="currentItem = currentItem - 1"
                    v-else
                    elevation="0"
                    color="error"
                    class="ma-2"
                    large
                    style="width:40%;"
                    type="button"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                    Kembali
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="currentItem !== items.length - 1"
                    @click.prevent="next"
                    elevation="0"
                    color="primary"
                    class="ma-2"
                    large
                    style="width:40%;"
                    type="button"
                  >
                    Next
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    elevation="0"
                    color="green"
                    class="white--text"
                    large
                    type="submit"
                    style="width:40%;"
                  >
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <div
            v-if="loader"
            class="d-flex justify-center align-center"
            style="position:absolute;top:0;z-index:3; left:0;width:100%; height:100%;background:rgba(255,255,255,0.5);"
          >
            <v-progress-circular
              :size="150"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </v-form>
    </v-card>

    <!-- <line-save @updatePage="loadPage" :assetDetail="{ id: created_id }" />
    <random-generate @updatePage="loadPage" :assetDetail="{ id: created_id }" />
    <add-sequence @updatePage="loadPage" :assetDetail="{ id: created_id }" /> -->
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  name: 'Asset-Form',
  components: {
    // LineSave: () => import('./components/LineSave'),
    // RandomGenerate: () => import('./components/RandomGenerate'),
    // AddSequence: () => import('./components/AddSequence')
  },
  data() {
    return {
      asset: buildType.apiURL('asset'),
      asset2: buildType.apiURL('asset2'),
      proc: buildType.apiURL('proc'),
      eSanqua: buildType.apiURL('esanqua'),
      hr: buildType.apiURL('hr'),
      dialog2: false,
      dialog: false,
      form: {
        matchWithAssetCode: false,
        searcFromEcatalogue: false,
        name: '',
        code: '',
        unit: null,
        category: null,
        plant: '',
        merk: '',
        company: null,
        location: null,
        purchase_date: '',

        generate_mode: null,
        number_of_lot_no: 0,
        len_digit_number: 0,
        random_type: '1',
        submitType: 'matchwithassetcode',

        lot_no: '',

        start_index: 0,
        is_padding: 1,
        number_of_padding: 0,
        padding_place: 'left',
        padding_with: ''
      },
      category: {
        name: '',
        code: ''
      },
      loading: false,
      loader: false,

      search: null,
      selectedAsset: null,
      company: null,
      result: [],
      dropDownCompany: [],
      codeRules: [],
      selectedCodeRules: [],
      categoryRules: [],
      merkRules: [],
      unitRules: [],
      nameRules: [],
      selectedNameRules: [],
      categoryNameRules: [],
      lotRules: [],
      lotTotalRules: [],
      digitRules: [],
      paddingNumber: [],
      paddingWith: [],
      indexRules: [],
      purchaseRules: [],
      locationRules: [],
      companyRules: [],
      currentItem: 0,
      items: ['(1) Informasi Aset', '(2) Tambah Lot'],
      text: 'Lorem ipsum dolor sit ame',
      step: 1,

      headers: [
        { text: 'No', value: 'no', align: 'left', sortable: false },
        { text: 'Nama aset', value: 'name', align: 'left', sortable: false },
        {
          text: 'Kode aset',
          value: 'code',
          align: 'left',
          sortable: false
        },
        {
          text: 'Kategori',
          value: 'category',
          align: 'left',
          sortable: false
        },
        {
          text: 'Merk',
          value: 'merk',
          align: 'left',
          sortable: false
        },
        {
          text: 'Total stock',
          value: 'total_stock',
          align: 'left',
          sortable: false
        },
        {
          text: 'Stok saat ini',
          value: 'current_stock',
          align: 'left',
          sortable: false
        }
      ],
      created_id: '',
      getDropdownAssetCategory: []
    }
  },
  computed: {
    ...mapGetters([
      'getUserProfile',
      'getDropdownLocation',
      // 'getDropdownAssetCategory',
      'getDropdownUnit'
    ])
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
    selectedAsset() {
      console.log(this.selectedAsset)
    },
    currentItem() {
      console.log(this.currentItem)
    },

    'form.company'() {
      this.dropdownLocation(this.form.company.id)
    },
    'form.submitType'() {
      console.log(this.form.submitType)
      this.form.purchase_date = ''

      this.form.generate_mode = null
      this.form.number_of_lot_no = 0
      this.form.len_digit_number = 0
      // this.form.random_type = '1'
      this.form.lot_no = ''

      this.form.start_index = 0
      this.form.is_padding = 1
      this.form.number_of_padding = 0
      this.form.padding_place = 'left'
      this.form.padding_with = ''

      // if (this.form.submitType === 'matchwithassetcode') {
      //   // this.form.code = this.selectedAsset.code
      //   console.log(this.form.searcFromEcatalogue)
      // } else {
      //   // this.form.code = ''
      // }
    },
    'form.searcFromEcatalogue'() {
      console.log(this.form.searcFromEcatalogue)
      this.form.name = ''
      this.form.code = ''
      this.form.unit = null
      this.form.category = null
      this.form.merk = ''
      this.selectedAsset = null
    }
  },
  mounted() {
    this.loadPage()
  },
  methods: {
    ...mapMutations(['setParamAsset']),
    ...mapActions([
      'dropdownLocation',
      'dropdownAssetCategory',
      'dropdownUnit'
    ]),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    next() {
      // this.companyRules = [v => !!v || 'Required']
      // this.unitRules = [v => !!v || 'Required']
      // this.categoryRules = [v => !!v || 'Required']
      // this.nameRules = [v => !!v || 'Required']
      // this.codeRules = [v => !!v || 'Required']
      // const self = this
      // setTimeout(function() {
      //   if (self.$refs.entryForm.validate()) {
      //     self.currentItem = self.currentItem + 1
      //   }
      // })
      this.currentItem = this.currentItem + 1
    },
    querySelections(v) {
      this.loading = true
      axios
        .get(
          `${this.proc}master/product/list?offset=0&limit=all&keyword=${v}&order_by=code&order_type=asc`
        )
        .then(res => {
          console.log(res)
          this.result = res.data.data
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    async dropdownCompany() {
      const application = this.getUserProfile.level
      const result = application.find(({ id }) => id === '1' || id === '29')
      if (result !== undefined) {
        const company = await axios
          .get(`${this.eSanqua}master/universal/plant/dropdown`)
          .then(res => {
            return res.data.data
          })
          .catch(err => {
            console.log(err)
            return []
          })

        if (application.find(({ id }) => id === '1') !== undefined) {
          return (this.dropDownCompany = company)
        } else if (
          application.find(({ id }) => id === '29') !== undefined &&
          this.getUserProfile.employee.company.plant_id === '6'
        ) {
          return (this.dropDownCompany = company)
        }
      }

      this.dropDownCompany = [
        {
          id: this.getUserProfile.sanqua_company_id,
          name: this.getUserProfile.sanqua_company_name
        }
      ]
      this.form.company = {
        id: this.getUserProfile.sanqua_company_id,
        name: this.getUserProfile.sanqua_company_name
      }
      return null
    },
    clear() {
      this.form = {}
    },
    close() {
      this.form.company = null
      this.selectedAsset = null
      this.dropDownCompany = []
      this.$router.push('/asset-management/aset-kepemilikan')
    },
    loadPage() {
      this.dropdownCompany()
      // this.dropdownAssetCategory()
      this.dropdownAssetCategory().then(res => {
        this.getDropdownAssetCategory = res
      })
      this.dropdownUnit()
      // this.company = this.getUserProfile.employee.company.plant_id
      this.dropdownLocation(this.getUserProfile.employee.company.plant_id)
      setTimeout(() => {
        console.log(this.getDropdownLocation)
      }, 300)
    },
    submit() {
      this.unitRules = [v => !!v || 'Unit is required']
      this.categoryRules = [v => !!v || 'Category is required']
      if (this.form.searcFromEcatalogue) {
        this.selectedNameRules = [v => !!v || 'Name is required']
        this.selectedCodeRules = [v => !!v || 'Code is required']
      } else {
        this.nameRules = [v => !!v || 'Name is required']
        this.codeRules = [v => !!v || 'Code is required']
      }

      this.purchaseRules = [v => !!v || 'Purchase date is required']
      this.locationRules = [v => !!v || 'Location is required']
      this.companyRules = [v => !!v || 'Company is required']

      switch (this.form.submitType) {
        case 'matchwithassetcode':
          this.lotRules = [v => !!v || 'Lot Code is required']
          break
        case 'manual':
          this.lotRules = [v => !!v || 'Lot Code is required']
          break
        case 'random':
          this.digitRules = [v => !!v || 'Digit Total is required']
          this.lotTotalRules = [v => !!v || 'Lot Total is required']
          break
        case 'sequence':
          this.lotTotalRules = [v => !!v || 'Lot Total is required']
          this.paddingNumber = [v => !!v || 'Number of padding is required']
          this.paddingWith = [v => !!v || 'Padding character is required']
          this.indexRules = [v => !!v || 'Start Index is required']
          break
      }
      // this.merkRules = [v => !!v || 'Merk is required']
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      let newForm = {}
      if (this.form.searcFromEcatalogue) {
        newForm = {
          act: 'add',
          code: this.selectedAsset.code,
          name: this.selectedAsset.name,
          category_id: this.selectedAsset.category.id,
          category_name: this.selectedAsset.category.name,
          unit_id: this.selectedAsset.unit.id,
          unit_name: this.selectedAsset.unit.name,
          company_id: this.form.company.id,
          company_name: this.form.company.name,
          merk: this.selectedAsset.merk
        }
      } else {
        newForm = {
          act: 'add',
          code: this.form.code,
          name: this.form.name,
          category_id: this.form.category.id,
          category_name: this.form.category.name,
          unit_id: this.form.unit.id,
          unit_name: this.form.unit.name,
          company_id: this.form.company.id,
          company_name: this.form.company.name,
          merk: this.form.merk
        }
      }
      this.save(newForm)
    },
    save(form) {
      this.loader = true
      axios
        .post(`${this.asset}asset/save`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.saveLot(res)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    saveLot(payload) {
      const form = {
        act: 'add',
        asset_id: payload.data.created_id,
        // location_id: this.form.location === null ? 5 : this.form.location.id,
        location_id: this.form.location === null ? 0 : this.form.location.id,
        location_name:
          this.form.location === null ? '' : this.form.location.name,
        // location_name:
        //   this.form.location === null ? 'MIS' : this.form.location.name,
        purchase_date: this.form.purchase_date
      }
      switch (this.form.submitType) {
        case 'matchwithassetcode':
          if (this.form.searcFromEcatalogue) {
            Object.assign(form, { lot_no: this.selectedAsset.code })
          } else {
            Object.assign(form, { lot_no: this.form.code })
          }
          break
        case 'manual':
          Object.assign(form, { lot_no: this.form.lot_no })
          break
        case 'random':
          delete form.add
          Object.assign(form, {
            generate_mode: 2,
            number_of_lot_no: Number(this.form.number_of_lot_no),
            len_digit_number: Number(this.form.len_digit_number),
            random_type: Number(this.form.random_type),
            product_code: this.form.searcFromEcatalogue
              ? this.selectedAsset.code
              : this.form.code
          })
          break
        case 'sequence':
          delete form.add
          Object.assign(form, {
            generate_mode: 1,
            product_code: this.form.searcFromEcatalogue
              ? this.selectedAsset.code
              : this.form.code,

            is_padding: this.form.is_padding,
            start_index: Number(this.form.start_index),
            number_of_lot_no: Number(this.form.number_of_lot_no),
            number_of_padding: Number(this.form.number_of_padding),
            padding_place: this.form.padding_place,
            padding_with: this.form.padding_with
          })
          break
      }
      console.log(form)
      this.submitLot(form)
    },
    submitLot(form) {
      const save =
        this.form.submitType === 'matchwithassetcode' ||
        this.form.submitType === 'manual'
          ? 'save'
          : 'generate'
      axios
        .post(`${this.asset}asset/lot/${save}`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            setTimeout(() => {
              this.showMsgDialog('success', res.data.status_msg, false)
              this.setParamAsset({ id: form.asset_id })
              setTimeout(() => {
                this.$router.push('/asset-management/aset-kepemilikan/detail')
              }, 300)
            }, 1000)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    categoryClose() {
      this.category.name = ''
      this.dialog2 = false
    },
    categoryAdd() {
      // this.categoryNameRules = [v => !!v || 'Name is required']
      // const self = this
      // setTimeout(function() {
      //   if (self.$refs.entryForm.validate()) {
      this.categorySubmit()
      //   }
      // })
    },
    categorySubmit() {
      this.loading = true
      const form = {
        act: 'add',
        code: this.category.code,
        name: this.category.name
      }

      axios
        .post(`${this.asset}asset_category/save`, form)
        .then(res => {
          console.log(res)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.initDropdownAssetCategory(res, form)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    async initDropdownAssetCategory(res, form) {
      await new Promise(resolve => {
        this.dropdownAssetCategory().then(res => {
          resolve()
          this.getDropdownAssetCategory = res
        })
      })
      // console.log(res)
      // setTimeout(() => {
      this.form.category = {
        id: res.data.clear_id,
        name: form.name
      }
      this.loading = false
      this.dialog2 = false
      // }, 500)
    }
    // updatePage() {
    //   this.setParamAsset({ id: this.created_id })
    //   setTimeout(() => {
    //     this.$router.push('/asset-management/aset-kepemilikan/detail')
    //   }, 300)
    // }
  }
}
</script>
<style lang="scss" scoped></style>
